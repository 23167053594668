/*
 * @Author: your name
 * @Date: 2020-12-11 10:19:32
 * @LastEditTime: 2021-02-03 17:29:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\api\user.js
 */
import { post, fetch, deletes, patch } from "@/utils/http";
import axios from "@/utils/http";
//------------------------------------------------------------
//确认导入现油核销码
export function confirmImport(title, auditCode, params) {
  return post(
    `/cancelCode/confirmImport?title=${title}&auditCode=${auditCode}`,
    params
  );
}

//确认导入实名发放 codeType 0.核销码,1.实名发放,2.直接充值
export function auditBatchImportBatch(params) {
  return axios.post(
    `/auditBatch/importBatch?codeType=${params.codeType}&title=${params.title}&auditCode=${params.auditCode}&fileUrl=${params.fileUrl}`,
    params.list,
    {
      timeout: 180000,
    }
  );
}
// 查看(折扣券批次列表-根据批次id获取客户列表)
export function getBatchVoucherList(params) {
  return fetch("/wlynVouchersUserRelation/getBatchVoucherList", params);
}

// 查看(根据批次id获取客户列表)
export function getBatchCustomerList(params) {
  return fetch("/cancelCode/getBatchCustomerList", params);
}

// 根据批次id获取实名发放客户列表
export function queryAutonymListById(params) {
  return post("/auditBatch/queryAutonymListById", params);
}
// 按客户id撤回接口
export function recallAutonym(params) {
  return post("/auditBatch/recallAutonym", params);
}
// 撤回卡券
export function backVouchers(params) {
  return post("/wlynVouchersUserRelation/backVouchers/" + params);
}

// 修改单个客户信息
export function updateSingleInfo(params) {
  return post("/cancelCode/updateSingleInfo", params);
}
// 删除单个客户信息
export function deleteAutonym(params) {
  return fetch("/wlynIntegral/delIntegralInfo/" + params);
}

//实名发放编辑批次详情接口
export function editAutonymList(params) {
  return post("/auditBatch/editAutonymList", params);
}
//生成单个实名发放
export function singleAddBatch(params) {
  return axios.post("/auditBatch/singleAddBatch", params);
}

//生成单个现油核销码
export function singleCancelCode(params) {
  return post("/cancelCode/singleCancelCode", params);
}
// 条件查询按客户实名发放、核销码列表
export function queryAutonymList(params) {
  return fetch("/auditBatch/queryAutonymList", params);
}
// 条件查询按批次实名发放、核销码列表
export function queryBatchList(params) {
  return fetch("/auditBatch/queryBatchList", params);
}
// 批次折扣券列表
export function voucherQueryList(params) {
  return fetch("/auditBatch/voucherQueryList", params);
}

//确认批量导入期油
export function confirmImportVouchers(params) {
  return post("/wlynVouchers/confirmImportVouchers", params);
}

// 根据批次id获取期油套餐发放列表
export function getVouchersPackageIssuePage(params) {
  return fetch("/wlynVouchersPackageIssue/getVouchersPackageIssuePage", params);
}

//确认批量导入期油套餐
export function confirmImportPackage(params) {
  return post("/wlynVouchersPackageIssue/confirmImportPackage", params);
}
//删除卡劵批次
export function delVouchersUserRelation(params) {
  return axios.get(
    "/wlynVouchersUserRelation/delVouchersUserRelation/" + params
  );
}
//根据id删除批次主信息表
export function delAuditBatch(params) {
  return axios.get("/auditBatch/delAuditBatch/" + params);
}
// -------------------------------- 审批接口管理
// 提交审批
export function submitApproval(params) {
  return axios.post("/wlynApproval/submitApproval", params, {
    timeout: 180000,
  });
}
// 审批同意或退回
export function agreeOrBack(params) {
  return post("/wlynApproval/agreeOrBack", params);
}
// 审批中心列表
export function getApprovalList(params) {
  return fetch("/wlynApproval/getApprovalList", params);
}

// 获取审批详情
export function getApprovalInfo(params) {
  return fetch("/wlynApproval/getApprovalInfo", params);
}

// 获取折扣券审批详情
export function getVoucherApprovalInfo(params) {
  return fetch("/wlynApproval/getVoucherApprovalInfo", params);
}

// 审批中心批次撤回
export function withdraw(params) {
  return post("/wlynApproval/withdraw/" + params);
}
//按批次条件查询总金额
export function queryBatchTotalAmount(params) {
  return axios.get("/auditBatch/queryBatchTotalAmount", {
    params,
  });
}
// 查询e代驾发放记录分页列表
export function getEdaijiaGrantRecordPage(params) {
  return post("/wlynEdaijiaGrantRecord/getEdaijiaGrantRecordPage/", params);
}
//导出e代驾发放记录列表excel
// export function exportEdaijiaGrantRecordExcel(params) {
//   return axios.get("/wlynEdaijiaGrantRecord/exportEdaijiaGrantRecordExcel", {
//     params,
//   });
// }
//是否撤销
export function isRevoke(params) {
  return axios.get("/wlynEdaijiaGrantRecord/isRevoke", {
    params,
  });
}
