<template>
  <div>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 260px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="title" align="center" label="标题" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="submitByName" align="center" label="提交人"></el-table-column>
      <el-table-column prop="submitOrgName" align="center" label="提交机构"></el-table-column>
      <el-table-column prop="auditCode" align="center" label="审批单号" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="submitTime" align="center" label="发放时间" width="150"></el-table-column>
      <el-table-column prop="vouchersAmount" align="center" label="总金额（元）"></el-table-column>
      <el-table-column prop="vouchersNumber" align="center" label="客户数量（人）" width="120"></el-table-column>
      <el-table-column prop="isUse" align="center" label="审批状态" width="120">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" size="mini" disable-transitions :type="auditStatusTag[scope.row.auditStatus].type" v-text="auditStatusTag[scope.row.auditStatus].text">
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="approverName" align="center" label="审批人" width="110"></el-table-column>
      <!-- <el-table-column prop="approvalTime" align="center" label="审批时间" width="150"></el-table-column> -->
      <el-table-column prop="address" label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button type="text" style="color:#333" icon="el-icon-view" @click="editDetail(scope.row,1)">查看</el-button>
          <span v-if="scope.row.submitOrgId == submitOrg">
            <i class="el-icon-refresh-left btn-hover" @click="recall(scope.row.batchId)" v-if="[1].indexOf(scope.row.auditStatus) != -1">撤回</i>
            <i class="el-icon-circle-check btn-hover" @click="editDetail(scope.row,3)" v-if="[0,3].indexOf(scope.row.auditStatus) != -1">提交</i>
            <i class="el-icon-edit btn-hover" @click="editDetail(scope.row,2)" v-if="[0,3].indexOf(scope.row.auditStatus) != -1">修改</i>
            <i class="el-icon-delete btn-hover" @click="deleteAudit(scope.row.batchId)" v-if="[0,3].indexOf(scope.row.auditStatus) != -1">删除</i>
          </span>
          <!-- <i v-if="scope.row.auditStatus == 2" class="el-icon-folder-opened btn-hover" @click="exportOne(scope.row)">导出</i> -->
        </template>
      </el-table-column>
    </el-table>
    <EditBatch v-if="isEditDialog" :isAddDialog.sync="isEditDialog" :editId.sync="editId" :editType.sync="editType"></EditBatch>
  </div>
</template>

<script>
import { withdraw, delAuditBatch } from '@/api/codes'
import { auditStatusTag, exportCommon, getCookies } from '@/utils/utils'
import EditBatch from './editBatch.vue'
export default {
  name: "tableFirst",
  props: ['list'],
  components: { EditBatch },
  data() {
    return {
      submitOrg: getCookies('organizationId'),
      auditStatusTag: auditStatusTag,
      isEditDialog: false,
      editId: '',
      editType: ''
    }
  },
  watch: {
    isEditDialog() {
      this.$parent.getList();
    }
  },
  methods: {
    // type == 1 查看详情 type == 2 编辑
    editDetail(id, type) {
      this.editType = type;
      this.editId = id;
      this.isEditDialog = true;
    },

    recall(id) {
      this.$confirm('是否撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        withdraw(id)
          .then((response) => {
            this.$message({
              title: '错误',
              type: response.code == 200 ? 'success' : 'error',
              message: response.msg || response.error_msg || response.message,
              duration: 1500
            });
            this.$parent.getList();
          });
      })
    },
    deleteAudit(id) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        delAuditBatch(id)
          .then((response) => {
            this.$message({
              title: '错误',
              type: response.code == 200 ? 'success' : 'error',
              message: response.msg || response.error_msg || response.message,
              duration: 1500
            });
            this.$parent.getList();
          });
      })
    },
    exportOne(row) {
      let param = {
        batchId: row.batchId,
        batchType: row.batchType
      }
      exportCommon('/wlynVouchersUserRelation/exportExcel', param)
    }
  }
}
</script>

<style>
</style>