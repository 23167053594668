<template>
  <el-dialog title="单个发放" :visible.sync="isAddDialog" :lock-scroll="false" :close-on-click-modal="false" width="600px" :before-close="beforeClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="审批单号" prop="auditCode">
        <el-input v-model="form.auditCode" placeholder="请输入审批单号"></el-input>
      </el-form-item>
      <el-form-item label="客户手机号" prop="phone">
        <el-input v-model="form.phone" maxlength="11" placeholder="请输入电话(7-11位数字)" oninput="value=value.replace(/[^\d*]+/g, '')"></el-input>
      </el-form-item>
      <el-form-item label="客户姓名" prop="customerName">
        <el-input v-model="form.customerName" maxlength="16" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="发放油券" prop="vouchersId">
        <el-select class="more-select" v-model="form.vouchersId" placeholder="请选择期油券" style="width:360px;">
          <el-option v-for="(item,index) in vouchersList" :key="index" :label="item.vouchersTitle" :value="item.vouchersId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" v-debounce="onSubmit">确认发放</el-button>
        <el-button @click="dialogClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>

import { getVouchersList, createVouchers, getRegionTree } from '@/api/setting';
export default {
  props: ['isAddDialog'],
  data() {

    return {
      areaType: 0,             // 区域类型 0:省份 1:城市
      regionName: '',        // 发放区域
      vouchersList: [],
      form: {
        vouchersId: '',
        phone: '',
        vouchersType: 0,
        customerName: '',
        title: '',
        auditCode: ''
      },
      rules: {
        vouchersId: [
          { required: true, message: '请选择折扣券', trigger: 'change' },
        ],
        customerName: [
          { required: true, message: '客户名称不可为空', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '手机号码不可为空', trigger: 'blur' },
        ],
        title: [
          { required: true, message: '标题不可为空', trigger: 'blur' },
        ],
        auditCode: [{ required: true, message: "审批单号不可为空", trigger: "blur" }],
      },
    }
  },
  watch: {
    isAddDialog(n, o) {
      if (n) {
        this.form.phone = '';
        this.form.amount = '';
        this.form.vouchersType = 0;
        this.form.customerName = '';
      }
    }
  },
  created() {
    this.getVouchersList();
  },
  methods: {
    beforeClose() {
      this.$emit('update:isAddDialog', false)
    },
    getVouchersList() {
      let that = this;
      let param = {
        isPage: 0,
        pageNum: 1,
        pageSize: 100000000,
        vouchersType: 0,
        usedArea: '410000'
      };
      getVouchersList(param)
        .then((response) => {
          if (response.code == 200) {
            that.vouchersList = response.data.records;
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
    changeCheck(checkKey) {
      // 去中文正则
      this.form[checkKey] = this.form[checkKey].replace(/^(0+)|[^\d]+/g, '');
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit() {
      var that = this;
      let form = JSON.parse(JSON.stringify(that.form));
      form.codeType = this.$route.meta.batchType
      form.exchangeType = this.$route.meta.exchangeType
      this.$refs['form'].validate((valid) => {
        if (valid) {
          createVouchers(form)
            .then((response) => {
              if (response.code == 200) {
                that.dialogClose();
              }
              that.$message({
                title: '错误',
                type: response.code == 200 ? 'success' : 'error',
                message: response.msg || response.error_msg || response.message,
                duration: 1500
              });

            })
        } else {
          this.$message.error({
            title: '错误',
            message: '请检查重试',
            duration: 1500
          });
          return false;
        }
      })


    }
  }
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
</style>