<template>

  <el-dialog title="发放详情" :visible.sync="show" :lock-scroll="false" :close-on-click-modal="false" width="350px" :before-close="dialogClose">
    <el-form label-width="100px">
      <el-form-item label="客户名称">
        {{info.customerName}}
      </el-form-item>
      <el-form-item label="电话号码">
        {{info.bindPhone}}
      </el-form-item>
      <el-form-item label="兑换码" v-if="info.exchangeCode">
        {{info.exchangeCode}}
      </el-form-item>
      <el-form-item label="油券面额">
        {{info.deductibleAmount}} 元
      </el-form-item>
      <el-form-item label="发放时间">
        {{info.createTime}}
      </el-form-item>
      <el-form-item label="使用状态">
        {{typeList[info.isUsed] && typeList[info.isUsed].text}}
      </el-form-item>
      <el-form-item label="使用时间" v-show="info.usedTime">
        {{info.usedTime}}
      </el-form-item>
      <el-button @click="dialogClose">关闭</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'info',
  props: ['show', 'info'],
  data() {
    return {

      typeList: [
        { type: 'primary', text: '未使用' },
        { type: 'success', text: '已使用' },
        { type: 'warning', text: '已撤回' },
      ],
    }
  },
  methods: {
    dialogClose() {
      this.$emit('update:show', false)
    },
  }
}
</script>

<style>
</style>