<template>
  <div>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 260px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="organizationName" align="center" label="所属机构" show-overflow-tooltip></el-table-column>
      <el-table-column prop="deductibleAmount" align="center" label="油券面额（元）" width="120"></el-table-column>
      <el-table-column prop="exchangeCode" align="center" label="核销码" v-if="pathType==2"></el-table-column>
      <el-table-column prop="customerName" align="center" label="客户名称"></el-table-column>
      <el-table-column prop="bindPhone" align="center" label="手机号码" width="110"></el-table-column>
      <el-table-column prop="createByName" align="center" label="创建人" width="120"></el-table-column>
      <el-table-column prop="createTime" align="center" label="发放时间" width="150"></el-table-column>
      <el-table-column prop="auditStatus" align="center" label="审批状态" width="80">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" size="mini" disable-transitions :type="auditStatusTag[scope.row.auditStatus].type" v-text="auditStatusTag[scope.row.auditStatus].text"></el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="isUse" align="center" label="领取状态" width="100">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" size="mini" :type="typeList[scope.row.isReceive] && typeList[scope.row.isReceive].type" disable-transitions>{{typeList[scope.row.isReceive] && typeList[scope.row.isReceive].text}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="receiveTime" align="center" label="发放时间" width="120"></el-table-column>
      <el-table-column prop="createPhone" align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" style="color:#333" icon="el-icon-view" @click="showInfo(scope.row)">查看</el-button>
          <el-button v-if="scope.row.submitOrg == submitOrg" type="text" icon="el-icon-refresh-left" size="mini" class="btn-hover" @click="recall(scope.row.rid)" :disabled="scope.row.isUsed != 0">撤回</el-button>
        </template>
      </el-table-column>
    </el-table>
    <info :show.sync="dialogInfo" :info="info" v-if="dialogInfo"></info>
  </div>
</template>

<script>
import { backVouchers } from '@/api/codes'
import { auditStatusTag, getCookies } from '@/utils/utils'
import info from './info.vue'
export default {
  name: 'tableSecond',
  props: ['list', 'pathType'],
  components: { info },
  data() {
    return {
      submitOrg: getCookies('organizationId'),
      auditStatusTag: auditStatusTag,
      typeList: [
        { type: 'primary', text: '未领取' },
        { type: 'success', text: '已领取' },
        // { type: 'warning', text: '已撤回' },
      ],
      info: {},
      dialogInfo: false
    }
  },
  methods: {
    recall(id) {
      this.$confirm('是否撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        backVouchers(id)
          .then((response) => {
            this.$message({
              title: '错误',
              type: response.code == 200 ? 'success' : 'error',
              message: response.msg || response.error_msg || response.message,
              duration: 1500
            });
            this.$parent.getList();
          });
      })
    },
    showInfo(row) {
      this.info = row
      this.dialogInfo = true
    }
  }
}
</script>

<style>
</style>