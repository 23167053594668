
<template>
  <div>
    <div v-show="active=='first'" class="top-btn">
      <el-button icon="el-icon-plus" size="small" style="width:100px;" @click="isSingleAddDialog = true" type="primary">单个发放</el-button>
      <!-- <el-button icon="el-icon-plus" size="small" style="width:100px;" @click="isAddDialog = true" type="primary">多个新增</el-button> -->
      <el-button icon="el-icon-plus" size="small" style="width:100px;" @click="isBatchImportCode = true" type="primary">批量发放</el-button>
    </div>
    <el-form label-width="80px" size="mini" inline>
      <el-form-item label="发放类型" v-show="active=='second'">
        <el-select style="width:120px;" v-model="search.exchangeType" placeholder="选择发放类型" @change="handleCurrentChange(1)">
          <el-option v-for="(item,i) in typeList" :key="i" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="油券面额">
        <el-select style="width:120px;" v-model="search.amountStart" placeholder="选择油券面额" clearable>
          <el-option v-for="(item,i) in valueList" :key="i" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模糊查询" v-show="active=='second'">
        <el-input v-model="search.search" placeholder="姓名/手机号" style="width:150px;" clearable></el-input>
      </el-form-item>
      <el-form-item label="审批状态" v-show="active=='first'">
        <el-select style="width:120px;" v-model="search.auditStatus" placeholder="选择审批状态" clearable>
          <el-option v-for="(item,i) in grentList" :key="i" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="领取状态" v-show="active=='second'">
        <el-select style="width:120px;" v-model="search.isReceive" placeholder="选择领取状态" clearable>
          <el-option v-for="(item,i) in useList" :key="i" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <OrganizationSelect v-model="search.submitOrg" @reload="getList"></OrganizationSelect>
      <el-form-item label="发放时间">
        <el-date-picker style="width:320px" v-model="submitTime" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="领取时间" v-show="active=='second'">
        <el-date-picker style="width:320px;" v-model="grantTime" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        <!-- <el-button v-show="active=='second'" icon="el-icon-folder-opened" @click="exportList" type="primary">导出</el-button> -->
      </el-form-item>
    </el-form>
    <TableFirst v-if="active=='first'" :list="list"></TableFirst>
    <TableSecond v-if="active=='second'" :list="list" :pathType="pathType"></TableSecond>
    <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
    </el-pagination>
    <AddBatchCode :isAddDialog.sync="isAddDialog" v-if="isAddDialog"></AddBatchCode>
    <AddSingleCode :isAddDialog.sync="isSingleAddDialog" v-if="isSingleAddDialog"></AddSingleCode>
    <BatchImportCode :isAddDialog.sync="isBatchImportCode" v-if="isBatchImportCode"></BatchImportCode>
  </div>

</template>
<script>
import { queryBatchList, withdraw, voucherQueryList } from '@/api/codes'
import { getCookies, auditStatusTag, exportCommon } from '@/utils/utils'
import { baseURL } from '@/utils/http'

import OrganizationSelect from '@/components/OrganizationSelect.vue'
import AddBatchCode from './addBatchCode.vue'
import AddSingleCode from './addSingleCode.vue'
import BatchImportCode from './batchImportCode.vue'
import TableFirst from './tableFirst.vue'
import TableSecond from './tableSecond.vue'

export default {
  name: 'codesBatchList',
  components: {
    OrganizationSelect,
    AddBatchCode,
    AddSingleCode,
    BatchImportCode,
    TableFirst,
    TableSecond
  },
  props: ['active'],
  data() {
    return {
      auditStatusTag: auditStatusTag,
      url: baseURL,
      search: {
        submitOrg: '',
        amountStart: '',
        amountEnd: '',
        auditStatus: '',
        search: '',
        pageNum: 1,
        pageSize: 10,
        batchType: 8,
        exchangeType: 3,
        isReceive: '',
      },
      submitTime: '',
      grantTime: '',
      // dialog
      isAddDialog: false,
      isSingleAddDialog: false,
      editId: '',
      editType: '',            // 编辑状态 1是详情，2是编辑,''是添加
      organizationId: '',

      list: [],
      total: 0,//分页总页数
      valueList: [{ label: '全部', id: '' }, { label: '100元', id: 100 }, { label: '200元', id: 200 }, { label: '300元', id: 300 }],
      typeList: [{ label: '全部', id: '1,2,3' }, { label: '直接发放', id: '3' }, { label: '核销码发放', id: '1' }, { label: '前三后四发放', id: '2' }],
      useList: [{ label: '全部', id: '' }, { label: '未使用', id: 0 }, { label: '已使用', id: 1 }, { label: '已撤回', id: 2 }],
      grentList: [{ label: '全部', id: '' }, { label: '未提交', id: 0 }, { label: '审批中', id: 1 }, { label: '已审批', id: 2 }, { label: '已退回', id: 3 }, { label: '已撤回', id: 4 }],
      pathType: 1,
      isBatchImportCode: false,
    }
  },
  watch: {
    isSingleAddDialog() {
      this.getList();
    },
    isAddDialog() {
      this.getList();
    },
    isBatchImportCode() {
      this.getList();
    },

    active() {
      console.log(this.active)
      this.handleCurrentChange(1)
    }
  },
  created() {
    // this.search.submitOrg = getCookies('organizationId') == 1 ? '' : getCookies('organizationId')
    this.pathType = this.$route.meta.type
    this.search.batchType = this.$route.meta.batchType
    this.search.exchangeType = this.$route.meta.exchangeType
    // this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(this.search.exchangeType)
      this.search.pageNum = val;
      this.getList();
    },
    getList() {
      let param = Object.assign({}, this.search)
      if (this.active == 'first') {
        param['submitStartTime'] = this.submitTime && this.submitTime.length ? this.submitTime[0] : '';
        param['submitEndTime'] = this.submitTime && this.submitTime.length ? this.submitTime[1] : '';
        this.getFirst(param)
      } else if (this.active == 'second') {
        param['startTime'] = this.submitTime && this.submitTime.length ? this.submitTime[0] : '';
        param['endTime'] = this.submitTime && this.submitTime.length ? this.submitTime[1] : '';
        param['receiveStartTime'] = this.grantTime && this.grantTime.length ? this.grantTime[0] : '';
        param['receiveEndTime'] = this.grantTime && this.grantTime.length ? this.grantTime[1] : '';
        this.getSecond(param)
      }
    },

    getFirst(param) {
      queryBatchList(param)
        .then((response) => {
          if (response.code == 200) {
            this.total = response.data.total;
            this.list = response.data.records;
          } else {
            this.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }
        })
    },
    getSecond(param) {
      voucherQueryList(param)
        .then((response) => {
          if (response.code == 200) {
            this.total = response.data.total;
            this.list = response.data.records;
          }
        })
    },
    exportList() {
      let param = Object.assign({}, this.search)
      param['startTime'] = this.submitTime && this.submitTime.length ? this.submitTime[0] : '';
      param['endTime'] = this.submitTime && this.submitTime.length ? this.submitTime[1] : '';
      param['receiveStartTime'] = this.grantTime && this.grantTime.length ? this.grantTime[0] : '';
      param['receiveEndTime'] = this.grantTime && this.grantTime.length ? this.grantTime[1] : '';
      param['userId'] = sessionStorage.getItem('wlyn_sysId')
      param.submitOrg = param.submitOrg || getCookies('organizationId')
      exportCommon('/auditBatch/exportVoucherExcel', param)
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";
.el-tag-style {
  width: 60px;
  text-align: center;
}
.form-item-box {
  float: left;
  height: 46px;
}
.el-form-item {
  margin: 0;
}

.btn-blue-hover {
  margin: 0 6px;
  color: $hoverRed;
}
.btn-blue-hover:hover {
  color: $hoverYellow;
}

.btn-red-hover {
  margin: 0 6px;
  color: $hoverBlue;
}
.btn-red-hover:hover {
  color: $hoverYellow;
}
</style>